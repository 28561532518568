import ControlGroup from "@amzn/meridian/control-group";
import RadioButton from "@amzn/meridian/radio-button";
import React from "react";
import { RadioButtonComponentProps, RadioButtonDataType } from "./radio-button.util.type";
import Text from "@amzn/meridian/text";
import Tooltip from "@amzn/meridian/tooltip"
import './radio-button.util.scss'
import Box from "@amzn/meridian/box";
import { IS_AFTX_REQUEST } from "src/utils/aftx-utils";

export const RadioButtonUtil = ({ radioButtonData,value, setValue, error, customCss }: RadioButtonComponentProps): JSX.Element => {
    let currentCss :typeof customCss="radio-util"
    if (currentCss){
        currentCss=customCss
    }
    return (
        <Box className={currentCss}> 
        <ControlGroup
            legend={radioButtonData.label}
            onChange={(value: string) => setValue(value)}
            value={value}
        >
            {radioButtonData.radioButtonValues.map((item) => {
                return (
                    <RadioButton key={item.value + "-key"} value={item.value}>
                        <Text color={error ? "error" : undefined}>{item.visibleText}</Text>
                    </RadioButton>
                );
            })}
        </ControlGroup>
        </Box>
    )
}

export const RadioButtonInlineFormatUtil = ({ radioButtonData, setValue, value, error ,customisedStyle, hasTooltip  }: RadioButtonComponentProps) => {
    let currentStyle : typeof customisedStyle =  {display: "grid",gridGap: "5px", gridTemplateColumns: "3fr 1fr 1fr", width: "300px" }
    if (customisedStyle){
        currentStyle = customisedStyle
    }
   
    return (
        <div style={currentStyle}>
            {radioButtonData.radioButtonValues.map((item) => {
                //AFT-X users should not see 'container', 'pkglegdetail', 'resourceId', 'trailer' radio buttons.
                if (renderAFTXSpecificRadioButtons(item.value))
                    return null;
                return (
                    <RadioButton
                    key={item.value + "-key"}
                    checked={value === item.value}
                    onChange={value => setValue(value)}
                    value={item.value}
                >
                    {hasTooltip  && item.toolTipValue ?
                        <Tooltip position="top" title={item.toolTipValue} id="myTooltip">
                            <Text color={error ? "error" : undefined}>{item.visibleText}</Text>
                        </Tooltip>
                        :
                        <Text color={error ? "error" : undefined}>{item.visibleText}</Text>
                    }
                </RadioButton>
                );
            })}

        </div>
    )
}
const renderAFTXSpecificRadioButtons = (itemValue: string): boolean => {
    if (!IS_AFTX_REQUEST) {
        return false;
    }

    const excludedValues = ['container', 'pkglegdetail', 'resourceId', 'trailer'];
    return excludedValues.includes(itemValue);
};