import React from "react";
import {MastheadMenuButton} from "@amzn/meridian/masthead";

const AFTX_DOMAIN_SUFFIX = '.aftx.amazonoperations.app'

export const isAftxDomain = () => {
    const hostname = window.location.hostname
    return hostname.endsWith(AFTX_DOMAIN_SUFFIX)
}
export const IS_AFTX_REQUEST: boolean = isAftxDomain()

export function HideForAftxUsers({children}: { children: React.ReactNode }) {
    if (IS_AFTX_REQUEST) {
        return <></>; // Return empty fragment to not render anything in the DOM
    }
    return <>{children}</>;
}

export const hideEventForAftxUsers = (eventName: string, eventHandler: () => void) => {
    if (IS_AFTX_REQUEST) {
        return {
            [eventName]: (e: Event) => {
                // Prevent default behavior since we want to hide this event for AFT-X users
                e.preventDefault();
            }
        };
    }
    return {
        [eventName]: eventHandler
    }
}
export function hideAttributesForAFTX(value: string) {
    if (IS_AFTX_REQUEST) {
        return getEmptyString();
    }
    return value;

    // Return an empty string for any UI elements(links, labels or any other attributes)
    // that we want to hide for AFT-X users
    function getEmptyString() {
        return "";
    }
}
/**
 * Interface defining the expected props for the `CustomMastheadMenuButton` component.
 */
interface CustomMastheadMenuButtonProps {
    // Define the properties
    onClick: () => void;
    open: boolean;
}
/**
 * Custom Masthead Menu Button
 *
 * This component is a wrapper around the `MastheadMenuButton` component from "@amzn/meridian/masthead", providing additional
 * functionality and type safety for the component's props.
 *
 * @component
 * @example
 * <CustomMastheadMenuButton
 *   onClick={() => handleMenuButton(!isMenuOpen)}
 *   open={true}
 * />
 */
export const CustomMastheadMenuButton = (props: CustomMastheadMenuButtonProps) => {
    /**
     * Determines if the current request is an AFTX request.
     * If true, the component will not render anything.
     */
    if (IS_AFTX_REQUEST) {
        return <></>
    }
    /**
     * Renders the `MastheadMenuButton` component, passing along the received props.
     */
    return <MastheadMenuButton {...props} />;
};
/**
 * Exposes the `MastheadMenuButton` component as the `WrappedComponent` property of the
 * `CustomMastheadMenuButton` component.
 */
CustomMastheadMenuButton.WrappedComponent = MastheadMenuButton;


