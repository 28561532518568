import React, { useEffect, useState } from "react";
import { AfnInputComponentPropsType } from "src/pages/visibility-page/components/afn/afn.component.type";
import { RadioButtonInlineFormatUtil } from "src/components/helpers/radio-button.util/radio-button.util";
import { DropDownUtilComponent } from "src/components/helpers/dropdown-util/dropdown-util";
import { InputFieldUtil } from "src/components/helpers/input-field-util/input-field-util";
import { UserGuideModal } from  "src/components/helpers/user-guide-modal/user-guide-modal";
import { afnShipmentTypeData, FormatRadioButton, nodeInfo } from "src/common/constants/afn-package-details.const";
import Box from "@amzn/meridian/box";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Textarea from "@amzn/meridian/textarea";
import Checkbox from "@amzn/meridian/checkbox";
import Button from "@amzn/meridian/button";
import Loader from "@amzn/meridian/loader";
import VisibilityNavTabComponent from "../visibility-navtab/visibility-navTab.component";
import Text from "@amzn/meridian/text";
import Responsive from "@amzn/meridian/responsive"
import { CY_SELECTORS } from "src/common/constants/utils.const";
import { filterPropsByPrefix } from "src/utils/custom-utils";
import Tooltip from "@amzn/meridian/tooltip";
import Toggle from "@amzn/meridian/toggle";
import { BulkSearchResultsModalComponent } from "./results/bulksearch-results-modal.component";
import { getDataForRegion as translateText} from "src/common/constants/translations.const";
import { BULK_SEARCH_USER_GUIDE } from "./afn.config";
import newLogo from 'src/images/new_icon.png';
import searchHistoryIcon from 'src/images/search_history.png';
import {HideForAftxUsers} from "src/utils/aftx-utils";


export const AfnInputComponent = (props: AfnInputComponentPropsType) => {
    
    const { afnForm, setAfnForm, afnMissingFields, setAfnMissingFields, setAfnResults, isSheetOpen, setIsSheetOpen, handleSubmit, getAfnIncompleteFields  } = props
    const updateForm = (field: string, value: string | boolean) => {
        setAfnForm({ ...afnForm, [field]: value })
        const tempMissingFields = { ...afnMissingFields }
        delete tempMissingFields[field]
        setAfnMissingFields({ ...tempMissingFields })
    }
    const [isBulkSearchHistoryVisible, setIsBulkSearchHistoryVisible] = useState(false)

    useEffect(() => {
        // Bulk Search is not enabled for container search 
        if (afnForm.bulkSearchEnabled && afnForm.shipmentType =="container"){
            setAfnForm({...afnForm,bulkSearchEnabled:false})
        }
     }, [afnForm]);

    const getSearchButtonText =() =>{
        return afnForm.bulkSearchEnabled ? "Bulk Search" : "Search"
    }
    const getSearchButtonLoadingText =() =>{
        if(afnForm.bulkSearchEnabled){
            return "Submitting Bulk Search Request" 
        } 
        if (props.searchButtonLoadingText !=null && props.searchButtonLoadingText !="" ){
            return props.searchButtonLoadingText
        }
        return "Loading ..."
    }

    let allowedForDeepSearch = ["scannable", "shipment"];

    const isSearchBtnDisabled = () =>{
        return afnForm.isLoading || Object.keys(afnMissingFields).length != 0
    }

    const getSearchInputAreaPlaceHolderText = () =>
        afnForm.bulkSearchEnabled
          ? "Please Enter Search Id Based on Shipment Type (Max 1000 Ids)"
          : "Please Enter Search Id Based on Shipment Type";
    
    return (
        <Column alignmentHorizontal="center" spacing="none" >
            <VisibilityNavTabComponent tab="Home" />
            <Box width="75%" type="outline" spacingInset="400 400 100 400" maxWidth="1400px">
                <div className="afn-search-component">
                    <Row alignmentHorizontal="start" widths="fills" spacing="400" wrap="down">
                    <div className="afn-text-area">
                        <Textarea
                            value={afnForm.searchId}
                            onChange={(value: string) => {
                                updateForm("searchId", value)
                            }}
                            
                            placeholder={getSearchInputAreaPlaceHolderText()}
                            error={afnMissingFields.hasOwnProperty("searchId")}
                            size="medium"
                            rows={2}
                            resize={"none"}
                            width={"100%"}
                            errorMessage={afnMissingFields["searchId"] ?? undefined}
                            {...filterPropsByPrefix(props, CY_SELECTORS)}
                        />
                    </div>
                        <Column className="afn-small-width">
                            <Row spacing="400" width="fills">
                                <Text type="h100">Format</Text>
                                <div className="afn-drop-down-field">
                                <DropDownUtilComponent dropDownData={FormatRadioButton}
                                    value={afnForm.format}
                                    error={afnMissingFields.hasOwnProperty("format")}
                                    width="85%"
                                    setValue={(value: string) => {
                                        updateForm("format", value)
                                        setAfnMissingFields({})
                                    }}
                                />
                                </div>
                            </Row>
                            {/*Remove Node input field in the UI for AFT-X  users*/}
                            <HideForAftxUsers>
                                <Row spacing="400" width="fills">
                                    <Text type="h100">Node</Text>
                                    <div className="afn-input-field">
                                        <InputFieldUtil inputFieldData={nodeInfo}
                                                        value={afnForm.nodeInfo}
                                                        setValue={(value: string) => {
                                                            updateForm("nodeInfo", value)
                                                        }}
                                                        size="small"
                                                        onBlur={() => {
                                                        }}
                                                        mask={""}
                                                        error={afnMissingFields.hasOwnProperty("nodeInfo")}
                                                        errorMessage={afnMissingFields["nodeInfo"] ?? undefined}
                                        />
                                    </div>
                                </Row>
                            </HideForAftxUsers>
                        </Column>
                        <Column  >
                        <Row  alignmentVertical="top" alignmentHorizontal="start">
                            <div className="bs_toggle">
                                {afnForm.shipmentType != "container" && <Toggle 
                                    checked={afnForm.bulkSearchEnabled} 
                                    onChange={(value: boolean) => {
                                            updateForm("bulkSearchEnabled", value)
                                                setAfnMissingFields({})
                                        }}
                                        size="medium" >
                                        <Row alignmentVertical="center" >
                                            <Box><Text breakWord={false}> Bulk Search </Text></Box>
                                            <Box > 
                                                <img style={{ marginLeft: '-10px',marginTop:"5px" }} 
                                                className="bulkSearch_newLogo" src={newLogo}
                                                width="25px" alt="Bulk Search Feature Launch Icon" />
                                            </Box>
                                        </Row>
                                 </Toggle> }
                            </div>
                            {afnForm.bulkSearchEnabled && (<HideForAftxUsers>
                                    <UserGuideModal {...BULK_SEARCH_USER_GUIDE} />
                                </HideForAftxUsers>
                            )}
                        </Row> 
                    </Column>
                    </Row> 
                  <Row spacingInset="300" widths="fills" wrap="down" >
                        <Box width={"100%"}>
                            <Responsive
                                query="min-width"
                                props={{
                                label: {
                                    default: "repeat(2,auto)",
                                    "500px": "repeat(2,auto)",
                                    "720px": "repeat(3,auto)",
                                    "1024px": "repeat(4,auto)",
                                    "1280px": "repeat(8,auto)",
                                },
                                }}
                            >
                            {props => <RadioButtonInlineFormatUtil radioButtonData={afnShipmentTypeData}
                                customisedStyle={{display: "grid", gridGap: "6px", gridTemplateColumns: props.label ,maxWidth:"1400px" }}
                                hasTooltip={true}
                                value={afnForm.shipmentType}
                                setValue={(value: string) => {
                                    updateForm("shipmentType", value)
                                    setAfnMissingFields({})
                                }}

                            />}
                            </Responsive>

                        </Box>
                    </Row>
                    <Row alignmentHorizontal="center"  width="100%">
                            {
                            <div className="checkbox-data">
                                <Checkbox disabled= {!allowedForDeepSearch.includes(afnForm.shipmentType)} checked={afnForm.multipleVersionDataCheckbox} onChange={(value: boolean) => {
                                    updateForm("multipleVersionDataCheckbox", value)
                                }}
                                    size="small"
                                >
                               <p>INCLUDE DATA FROM ALL PACKAGE VERSIONS (THIS IS AN EXPENSIVE OPERATION AND CAN TAKE SEVERAL SECONDS TO COMPLETE)</p>
                                </Checkbox>
                             </div>
                        }
                    </Row>
                    <Row alignmentHorizontal={"center"} spacingInset="300">
                        <Button disabled={isSearchBtnDisabled()}
                            size="medium"
                            data-cyid ="afn-input-search-btn"
                            onClick={() => {
                                if (Object.keys(afnMissingFields).length != 0) return;
                                setAfnForm({ ...afnForm, isLoading: true })
                                handleSubmit(false)
                            }}>
                            {afnForm.isLoading ?
                                <> {getSearchButtonLoadingText()} <pre /> <Loader type="circular" size="small" /> </> :
                                getSearchButtonText()
                            }
                        </Button>
                    </Row>
                    
                </div>
                {afnForm.bulkSearchEnabled && <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                    <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
                    <Tooltip position="end" title="Bulk Search History" id="bulkSearchHistoryToolTip">
                        <Button className="bulksearch_history_btn" type="icon" onClick={()=>{
                            setIsBulkSearchHistoryVisible(!isBulkSearchHistoryVisible)
                        }}>
                            <Box > 
                                    <img style={{ marginLeft: '-10px',marginTop:"5px" }} 
                                    className="searchHistoryIcon" src={searchHistoryIcon}
                                    width="25px" alt="Bulk Search Feature Launch Icon" />
                            </Box>
                        </Button>
                    </Tooltip>
                    {isBulkSearchHistoryVisible ?<BulkSearchResultsModalComponent  open = {isBulkSearchHistoryVisible} setOpen = {setIsBulkSearchHistoryVisible} /> :''}
                    </div>
                </div>
                }
                { afnForm.bulkSearchEnabled ? <Text  className="bulksearch_checkbox_text" type="b200" alignment="center"  >
                <div style={{ fontWeight: 900 }} > { translateText('bulkSearchNoteText') } </div>
                </Text> :"" }
            </Box >
        </Column>
    )
}